export const cassUtil = {
    name: 'cassUtil',
    data: () => ({
    }),
    methods: {
        calculateLastModifiedTime: function(modifiedStr) {
            if (modifiedStr === undefined) {
                return "Last Modified: n/a";
            }

            const modifiedDate = new Date(modifiedStr);

            const modifiedDiffMS = new Date() - modifiedDate; 
            const diffInMinutes = Math.floor(modifiedDiffMS / (1000 * 60));

            if (diffInMinutes < 1) {
                return "Last modified just now";
            } else if (diffInMinutes < 60) {
                return `Last modified ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
            } else if (diffInMinutes < 1440) {
                const diffInHours = Math.floor(diffInMinutes / 60);
                return `Last modified ${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
            } else {
                const diffInDays = Math.floor(diffInMinutes / 1440);
                return `Last modified ${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
            }
        },
        getShortId: function(uri) {
            const FRAMEWORK_PREFIX = "/api/data/schema.cassproject.org.0.4.Framework/";

            let uriObj;
            try {
                uriObj = new URL(uri);
            } catch (err) {
                return undefined;
            }
        
            let uriPath = uriObj.pathname;
        
            // For the demo, we are currently only allowing frameworks to be used for favorites
            // or metadata -- ensure that the default framework URI format will be used.
            //
            if (!uriPath.startsWith(FRAMEWORK_PREFIX)) {
                console.error(">> [Err 1] UNEXPECTED FRAMEWORK ID, CANNOT PROCEED: ", uri);
                return undefined;
            }
        
            let idSection = uriPath.substring(FRAMEWORK_PREFIX.length);
            let pathSections = idSection.split("/");
            if (pathSections.length > 2 || pathSections.length === 0) {
                console.error(">> [Err 2] UNEXPECTED FRAMEWORK ID, CANNOT PROCEED: ", uri);
                return undefined;
            }
        
            let rootID = pathSections[0];
            return `${uriObj.protocol}//${uriObj.hostname}/api/data/schema.cassproject.org.0.4.Framework/${rootID}`;
        },
        createFrameworkLink: function(uri) {
            let urlObj = new URL(uri);
            let uriPath = urlObj.pathname;
            const FRAMEWORK_PREFIX = "/api/data/schema.cassproject.org.0.4.Framework/";
            if (!uriPath.startsWith(FRAMEWORK_PREFIX)) {
                // alert("doesn't contain prefix: " + uriPath);
                return uri;
            }
            let idSection = uriPath.substring(FRAMEWORK_PREFIX.length);
            let pathSections = idSection.split("/");
            if (pathSections.length > 2) {
                // alert("Path sections > 2");
                return uri;
            } else if (pathSections.length === 2) {
                let split = uri.split("/");
                split.pop();
                uri = split.join("/");
            }
            return `#/framework?frameworkId=${uri}`;
        },
        getFrameworks: async function() {
            let me = this;
            return new Promise((resolve, reject) => {
                EcFramework.search(window.repo, "", function(success) {
                    if (success && Array.isArray(success)) {
                        let index = 1;
                        let frameworks = success.map(frameworkObj => {
                            return {
                                key: index++,
                                frameworkLink: me.createFrameworkLink(frameworkObj["@id"] || frameworkObj.id),
                                frameworkName: (frameworkObj.name["@value"] || frameworkObj.name),
                                frameworkURI: frameworkObj["@id"] || frameworkObj.id,
                                frameworkDescription: frameworkObj.description !== undefined ? (frameworkObj.description["@value"] || frameworkObj.description) : "No description.",
                                itemsCount: Array.isArray(frameworkObj.competency) ? frameworkObj.competency.length : 0,
                                publishDate: "Published on " + new Date(frameworkObj["schema:dateCreated"]).toLocaleDateString("en-US", {month: 'short', day: 'numeric', year: 'numeric'}),
                                lastUpdatedDate: me.calculateLastModifiedTime(frameworkObj["schema:dateModified"]),
                                lastUpdatedRaw: frameworkObj["schema:dateModified"]
                            };
                        });
                        resolve(frameworks);
                    }
                }, reject, null);
            });
        },
        postMetadataEndpoint: function(frameworkId) {
            let apiEndpoint = window.repo.selectedServer;
            if (typeof apiEndpoint !== "string") {
                console.error(">> Cannot find /api/metadata endpoint");
                return undefined;
            }
            if (!apiEndpoint.endsWith("/")) {
                apiEndpoint += "/";
            }
            return apiEndpoint + "metadata/upload?uri=" + encodeURIComponent(frameworkId);
        },
        getMetadataEndpoint: function(frameworkId) {
            let apiEndpoint = window.repo.selectedServer;
            if (typeof apiEndpoint !== "string") {
                console.error(">> Cannot find /api/metadata endpoint");
                return undefined;
            }
            if (!apiEndpoint.endsWith("/")) {
                apiEndpoint += "/";
            }
            return apiEndpoint + "metadata?uri=" + encodeURIComponent(frameworkId);
        },
        myHistoryEndpoint: function() {
            let apiEndpoint = window.repo.selectedServer;
            if (typeof apiEndpoint !== "string") {
                console.error(">> Cannot find /api/history endpoint");
                return undefined;
            }
            if (!apiEndpoint.endsWith("/")) {
                apiEndpoint += "/";
            }
            return apiEndpoint + "history/viewed";
        },
        getMyHistory: async function() {
            try {
                let res = await fetch(this.myHistoryEndpoint());
                let body = await res.json();
                return body;
            } catch (err) {
                return undefined;
            }
        },
        getMetadata: async function(frameworkId) {
            try {
                let endpoint = this.getMetadataEndpoint(frameworkId);
                console.log("ENDPOINT:", decodeURI(frameworkId));
                let res = await fetch(endpoint);
                let body = await res.json();
                if (body.data != null) {
                    let metadata = { 
                        ...body.data,
                        thumbnailSrc: `data:${body.contentType};base64, ${body.data}`
                    };
                    return metadata;
                }
                return undefined;
            } catch (err) {
                return undefined;
            }
        },
        getThumbnailSrc: async function(frameworkId) {
            try {
                let res = await fetch(this.getMetadataEndpoint(frameworkId));
                let body = await res.json();
                if (body.data != null) {
                    let src = `data:${body.contentType};base64, ${body.data}`;
                    return src;
                }
                return undefined;
            } catch (err) {
                return undefined;
            }
        },
        getOrganizationByEcPk(ecPk, successCallback, failureCallback) {
            let ecPkPem = ecPk.toPem();
            let paramObj = {};
            paramObj.size = 10000;
            EcOrganization.search(window.repo, '',
                async function(ecoa) {
                    for (let o of ecoa) {
                        try {
                            let groupPpkSet = await o.getOrgKeys();
                            for (let gPpk of groupPpkSet) {
                                if (gPpk && gPpk.toPk().toPem().equals(ecPkPem)) {
                                    successCallback(o);
                                    return;
                                }
                            }
                        } catch (e) {
                            // TODO Problem with EcOrganization update and creating encrypted value when only a reader...
                            // Anticipated workaround....login as group owner and save it.
                            // console.error("TODO...fix this...needs FRITZ input!!!!: " + e.toString());
                            failureCallback(e.toString());
                        }
                    }
                    successCallback(null);
                },
                function(err) {
                    failureCallback(err);
                },
                paramObj);
        },
        async getOrganizationEcPk(orgObj) {
            try {
                return (await orgObj.getCurrentOrgKey()).toPk();
                // let orgEvPpk = new EcEncryptedValue();
                // orgEvPpk.copyFrom(orgObj[this.GROUP_PPK_KEY]);
                // let orgPpk = EcPpk.fromPem(orgEvPpk.decryptIntoString());
                // return orgPpk.toPk();
            } catch (e) {
                // TODO Problem with EcOrganization update and creating encrypted value when only a reader...
                // Anticipated workaround....login as group owner and save it.
                return null;
            }
        },
        getPersonEcPk(personObj) {
            let personFingerprint = this.generateProbablePersonFingerprintFromShortId(personObj.shortId());
            if (personObj.owner) {
                for (let pkPem of personObj.owner) {
                    let ecPk = EcPk.fromPem(pkPem);
                    if (personFingerprint.equals(ecPk.fingerprint())) return ecPk;
                }
            }
            return null;
        },
        addAllOwnersFromObjectToObject(fromObj, toObj) {
            if (fromObj && fromObj.owner) {
                for (let pkPem of fromObj.owner) {
                    let ecPk = EcPk.fromPem(pkPem);
                    toObj.addOwner(ecPk);
                }
            }
        },
        setDefaultBrowserConfigId(configId) {
            localStorage.setItem("cassAuthoringToolDefaultBrowserConfigId", configId);
        },
        removeDefaultBrowserConfig() {
            localStorage.removeItem("cassAuthoringToolDefaultBrowserConfigId");
        },
        getDefaultBrowserConfigId() {
            return localStorage.getItem("cassAuthoringToolDefaultBrowserConfigId");
        },
        getBooleanValue(val) {
            if (typeof val === 'boolean') return val;
            else if (typeof val === 'string') {
                if (val.equalsIgnoreCase('true')) return true;
                else return false;
            } else return false;
        },
        addAllIdentityPksAsOwners(obj) {
            // let isEcrld = (obj instanceOf EcRemoteLinkedData);
            if (EcIdentityManager && EcIdentityManager.default.ids && EcIdentityManager.default.ids.length > 0) {
                for (let i = 0; i < EcIdentityManager.default.ids.length; i++) {
                    obj.addOwner(EcIdentityManager.default.ids[i].ppk.toPk());
                }
            }
        },
        isObjectOwnerless(obj) {
            if (!obj.owner || obj.owner.length === 0) return true;
            else return false;
        },
        doesAnyIdentityOwnObject(obj) {
            if (this.isAdmin()) return true;
            if (obj.canEditAny == null) return true;
            return obj.canEditAny(EcIdentityManager.default.getMyPks());
        },
        canEditAny: function(item) {
            if (this.isAdmin()) return true;
            if (item.canEditAny == null) return true;
            return item.canEditAny(EcIdentityManager.default.getMyPks());
        },
        isAdmin: function() {
            let adminKeys = window.repo.adminKeys;
            let userIds = EcIdentityManager.default.ids;
            if (!Array.isArray(adminKeys)) return false;
            if (!Array.isArray(userIds)) return false;
            for (let userId of userIds) {
                let userKey = userId.ppk.toPk().toPem();
                for (let adminKey of adminKeys) {
                    if (userKey === adminKey) {
                        return true;
                    }
                }
            }
            return false;
        },
        isUserAnAdmin: function(publicKey) {
            let adminKeys = window.repo.adminKeys;
            if (!Array.isArray(adminKeys)) return false;
            for (let adminKey of adminKeys) {
                if (publicKey === adminKey) {
                    return true;
                }
            }
            return false;
        },
        getToken: function(obj) {
            if (typeof obj.identifier === "string") {
                return obj.identifier;
            }
            if (Array.isArray(obj["owner"]) && obj["owner"].length > 0) {
                return obj["owner"][0];
            }
            return obj["type"];
        },
        xor: function(input, token) {
            return input;
            // input = typeof input === 'object' ? JSON.stringify(input) : input.toString();
            // token = typeof token === 'object' ? JSON.stringify(token) : token.toString();
            // let cipherText = '';
            // let length = input.length;
            // for (let i = 0; i < length; i++) {
            //     let codePoint = input.codePointAt(i) ^ token.codePointAt(Math.floor(i % token.length));
            //     cipherText += String.fromCodePoint(codePoint);
            // }
            // return cipherText;
        },
        decodePerson: function(personObj) {
            if (!personObj.__encrypted) return personObj;
            if (personObj["type"] !== "Person") return personObj;
            let token = this.getToken(personObj);
            return {
                ...personObj,
                name: this.xor(personObj.name, token),
                email: this.xor(personObj.email, token),
                username: this.xor(personObj.username, token)
            };
        },
        generateProbablePersonFingerprintFromShortId(personShortId) {
            return personShortId.substring(personShortId.lastIndexOf("/") + 1);
        },
        isPersonIdAnObjectOwner(personShortId, obj) {
            if (!obj.owner || obj.owner.length === 0) return false;
            let personFingerprint = this.generateProbablePersonFingerprintFromShortId(personShortId);
            for (let ownerPkPem of obj.owner) {
                let ownerFingerprint = EcPk.fromPem(ownerPkPem).fingerprint();
                if (ownerFingerprint.equals(personFingerprint)) return true;
            }
            return false;
        },
        isPersonIdAnObjectReader(personShortId, obj) {
            if (!obj.reader || obj.reader.length === 0) return false;
            let personFingerprint = this.generateProbablePersonFingerprintFromShortId(personShortId);
            for (let readerPkPem of obj.reader) {
                let readerFingerprint = EcPk.fromPem(readerPkPem).fingerprint();
                if (readerFingerprint.equals(personFingerprint)) return true;
            }
            // return obj.hasReader(EcPk.fromPem(pkPem));
            return false;
        },
        areAnyIdentitiesThisPerson(personObj) {
            let personFingerprint = personObj.getFingerprint();
            if (EcIdentityManager && EcIdentityManager.default.ids && EcIdentityManager.default.ids.length > 0) {
                for (let i = 0; i < EcIdentityManager.default.ids.length; i++) {
                    if (EcIdentityManager.default.ids[i].ppk.toPk().fingerprint().equals(personFingerprint)) return true;
                }
            }
            return false;
        },
        isPersonalIdentityAnObjectOwner(obj) {
            if (this.isAdmin()) return true;
            if (!obj.owner || obj.owner.length === 0) return false;
            let personalIdentPkPem = this.getPersonalIdentityPk().toPem();
            return obj.owner.includes(personalIdentPkPem);
        },
        getPersonalIdentityPk() {
            // assuming that the first identity is the user's personal identity
            if (EcIdentityManager && EcIdentityManager.default.ids && EcIdentityManager.default.ids.length > 0) {
                return EcIdentityManager.default.ids[0].ppk.toPk();
            } else return null;
        },
        buildEcAlignmentsFromRemoteLinkedData(ecrlda) {
            let ecaa = [];
            for (let ecrld of ecrlda) {
                let eca = new EcAlignment();
                eca.copyFrom(ecrld);
                ecaa.push(eca);
            }
            return ecaa;
        }
    },
    computed: {
        amLoggedIn: function() {
            if (EcIdentityManager && EcIdentityManager.default.ids && EcIdentityManager.default.ids.length > 0) return true;
            else return false;
        }
    }
};

